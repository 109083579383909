'use client';
import { useEffect, useState } from 'react';

import { getClientSideCookieValue } from '@cookies/GetClientSideCookieValue';
import { getClientUserId } from '@cookies/GetClientUserId';
import { formStateAtom } from '@core/Atoms/Pdp/FormState.atom';
import { searchParamsAtom } from '@core/Atoms/SearchParams/SearchParams.atom';
import { userIdAtom } from '@core/Atoms/User/UserId.atom';
import { currentVehicleAtom } from '@core/Atoms/Vehicle/CurrentVehicle.atom';
import { asyncVehicleByIdAtom } from '@core/Atoms/Vehicle/VehiclesStore.atom';
import { useModal } from '@core/Components/Modal/UseModal';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import { commonApiURL } from '@core/Environment/Variables';
import {
  createEventWithCallNumber,
  generateLeadTrackingData,
} from '@core/Tracking/LeadForms.tracking';
import { fetchJson } from '@core/Utils/Fetch/FetchJson';
import { isBusinessHours } from '@core/Utils/isBusinessHours/isBusinessHours';
import { getReCaptchaToken, loadGoogleRecaptcha } from '@core/Utils/loadGoogleRecaptcha';
import { isMobileAppAtom } from '@mobile-app/Atoms/IsMobileApp.atom';
import { sendNativeMessage } from '@mobile-app/Utils/SendNativeMessage';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { leadFormIdAtom } from 'Atoms/App/Pdp/LeadFormId.atom';

import { errorBoxOpenAtom } from 'Atoms/App/Plp/ErrorBoxOpenAtom';
import { showDealerNumberAtom } from 'Atoms/App/Plp/ShowDealerNumber';
import { LeadForm } from 'Components/LeadForms/LeadForm/LeadForm';
import { LeadFormMessagesKeys } from 'Components/LeadForms/Leadforms.messages';
import { leadModalHeaderText } from 'Components/LeadForms/Utils/Leadforms.util';
import { PdPMessagesKeys } from 'Components/Pdp/Pdp.messages';
import { useInitialMount } from 'Hooks/UseInitialMount';

import leadFormStyles from '../../LeadForms/LeadForm/LeadForm.module.css';

import ButtonLoader from './ButtonLoader/ButtonLoader';
import { CallBack } from './CallBack/CallBack';
import DealerNumber from './DealerNumber/DealerNumber';
import ErrorBox from './ErrorBox/ErrorBox';
import { SeeNumber } from './SeeNumber/SeeNumber';

import styles from './CallDealer.module.css';

interface CallDealerProps {
  vehicles: {
    heycarId: string;
    hasFinance: boolean;
    isReducedPrice: boolean;
  }[];
  locale: Locale;
  leadFormMessages: Record<LeadFormMessagesKeys, string>;
  messages: Record<PdPMessagesKeys, string>;
  strings: Record<string, any>;
  heycarId: string;
  hasCallbackCta?: boolean;
}

export const CallDealer = ({
  vehicles,
  locale,
  leadFormMessages,
  messages,
  strings,
  heycarId,
  hasCallbackCta,
}: CallDealerProps) => {
  const isInitialMount = useInitialMount();
  const [isNumberVisible, setIsNumberVisible] = useAtom(showDealerNumberAtom);
  const [isErrorBoxOpen, setIsErrorBoxOpen] = useAtom(errorBoxOpenAtom);
  const isMobileApp = useAtomValue(isMobileAppAtom)!;

  const setCurrentVehicle = useSetAtom(currentVehicleAtom);
  const vehicle = useAtomValue(asyncVehicleByIdAtom(heycarId));
  const setLeadFormId = useSetAtom(leadFormIdAtom);
  const userId = useAtomValue(userIdAtom);
  const formState = useAtomValue(formStateAtom);
  const searchParams = useAtomValue(searchParamsAtom);
  const { openModal } = useModal();

  const [phoneNumber, setPhoneNumber] = useState({
    isLoading: false,
    number: '',
    isError: false,
  });

  useEffect(() => {
    setIsNumberVisible('');
  }, []);

  useEffect(() => {
    if (!isInitialMount) {
      const { number, isLoading } = phoneNumber;
      if (number && !isLoading) {
        const query = window.matchMedia(`(max-width: 767px)`);
        if (query.matches) {
          document.location.href = `tel:${number}`;
        }
      }
    }
  }, [phoneNumber]);

  const facebookPixelValue = getClientSideCookieValue('_fbp');
  const facebookClickIdValue = getClientSideCookieValue('_fbc');

  const handleClick = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setIsNumberVisible(heycarId);

    const { callStart, callSubmit } = generateLeadTrackingData({
      searchParams,
      userId,
      formState,
      leadId: '',
      listingId: heycarId!,
      vehicle: vehicle!,
      leadFormId: 'call-dealer',
      ctaLocation: 'plp',
      leadAction: '',
    });

    if (isNumberVisible && isNumberVisible === heycarId) {
      const callSubmitEvent = createEventWithCallNumber(heycarId!, phoneNumber.number, 'plp');

      trackCustomEvent({ event: callSubmitEvent!, context: callSubmit.context });
      window.open(`tel:${phoneNumber.number}`, '_self');
    }

    if (isNumberVisible !== heycarId) {
      trackCustomEvent({ event: callStart.event!, context: callStart.context });
    }

    setPhoneNumber({
      ...phoneNumber,
      isLoading: true,
    });

    const payload = {
      metaData: {
        trackingId: getClientUserId(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        vehicle_uuid: heycarId,
        leadSource: 'web',
        utmSource: searchParams?.utm_source,
        utmMedium: searchParams?.utm_medium,
        utmCampaign: searchParams?.utm_campaign,
        gclid: searchParams?.gclid,
        cItemId: searchParams?.cItemId,
        userId: userId,
        msclkId: searchParams?.msclkId,
        fbc: facebookClickIdValue,
        fbp: facebookPixelValue,
      },
    };

    let ipAddress = '';

    try {
      const result = await loadGoogleRecaptcha(locale);
      if (result) {
        try {
          const recaptchaToken = await getReCaptchaToken('lead', locale);
          try {
            ipAddress = await fetchJson('/api/get-ip');
          } catch (error) {
            console.error('Error retrieving Client IP address', error);
          }
          const data: any = await fetchJson(`${commonApiURL}/call-leads-gateway/generate_number`, {
            method: 'POST',
            headers: {
              'Recaptcha-Token': recaptchaToken,
              'x-heycar-tenant': locale,
              'x-original-forwarded-for': ipAddress,
            },
            body: JSON.stringify(payload),
          });
          if (window.innerWidth < 768) {
            const callSubmitEvent = createEventWithCallNumber(
              heycarId!,
              data?.displayNumber,
              'plp',
            );
            trackCustomEvent({ event: callSubmitEvent!, context: callSubmit.context });
          }
          setPhoneNumber(prevPhoneNumber => {
            return {
              ...prevPhoneNumber,
              isError: false,
              number: data?.displayNumber,
              isLoading: false,
            };
          });
          if (isMobileApp) {
            sendNativeMessage({ triggerAction: { action: 'call', payload: data?.displayNumber } });
            return;
          }
        } catch (error) {
          setPhoneNumber({
            ...phoneNumber,
            isLoading: false,
            isError: true,
          });
          setIsErrorBoxOpen(true);
        }
      }
    } catch (error) {
      setPhoneNumber({
        ...phoneNumber,
        isLoading: false,
        isError: true,
      });
      setIsErrorBoxOpen(true);
    }
  };

  //TODO: can we extract the code in this file to be call dealer stuff and request callback stuff?
  const handleCallBackClick = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsNumberVisible('');
    setLeadFormId('request-callback');
    setCurrentVehicle(vehicle);

    openModal(<LeadForm strings={{ ...leadFormMessages, ...messages }} locale={locale} plp />, {
      header: leadModalHeaderText('request-callback', messages),
      modalClassName: leadFormStyles.modalContainer,
      modalBodyClassName: leadFormStyles.modalBodyClassName,
    });
  };

  const isBusinessHrs = isBusinessHours(vehicle?.dealer?.openingTimes);

  if (!isBusinessHrs || hasCallbackCta)
    return (
      <div
        className={styles.outerWrapper}
        data-test-id="requestCallBackButton"
        data-has-vehicle-obj={vehicle ? '1' : '0'}
        onClick={handleCallBackClick}
      >
        <CallBack strings={strings} />
      </div>
    );

  return (
    <div
      className={styles.outerWrapper}
      data-test-id="callDealerButton"
      data-has-vehicle-obj={vehicle ? '1' : '0'}
    >
      {isNumberVisible === heycarId && phoneNumber.isError && isErrorBoxOpen && vehicle && (
        <ErrorBox strings={strings} vehicle={vehicle!} vehicles={vehicles} />
      )}
      {phoneNumber.isError ? (
        <div className={styles.outerWrapper} onClick={handleCallBackClick}>
          <CallBack strings={strings} />
        </div>
      ) : (
        <div className={styles.wrapper} onClick={handleClick}>
          {isNumberVisible === heycarId ? (
            phoneNumber.isLoading ? (
              <ButtonLoader />
            ) : (
              <DealerNumber strings={strings} phoneNumber={phoneNumber.number} />
            )
          ) : (
            <SeeNumber strings={strings} />
          )}
        </div>
      )}
    </div>
  );
};

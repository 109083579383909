import Button from '@gds/Button/Button';

import Link from 'next/link';

import { searchWidgetMessages } from '../../SearchWidget/SearchWidget.messages';

import styles from './AutobizCta.module.css';

interface CtaProps {
  strings: Record<keyof typeof searchWidgetMessages, string>;
}

const AutobizCta = ({ strings }: CtaProps) => {
  return (
    <div className={styles.autobizCta}>
      <div className={styles.or}>
        <span className={styles.copy}>{strings.or}</span>
      </div>
      <Button
        Component={Link}
        aria-label="autobiz-button"
        className={styles.button}
        fullWidth
        href="/fr/vendre-ma-voiture"
        type="submit"
        variant="outlined"
      >
        {strings.autobizCta}
      </Button>
    </div>
  );
};

export default AutobizCta;
